import axios from 'axios'
import React, { useState } from 'react'
import { ProductCard } from './ProductCard'

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  physical,
  workshop,
  education,
  sst,
}) => {
  const [selected, setSelected] = useState(null)
  const [selectedWorkshop, setSelectedWorkshop] = useState(null) // [null, product_id]

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedWorkshop]}
        />

        <ProductCard
          cardTitle={'52nd MOA ASM 2023 Conference Ticket'}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null)
              setSelectedWorkshop(null)
            } else {
              setSelectedWorkshop(null)
            }
          }}
          handleRadio={() => {
            setSelectedWorkshop(null)
          }}
        />
        {/* <h6 className="text-center text-muted mt-4">OR</h6> */}

        <ProductCard
          cardTitle={'Ultrasound Workshop'}
          products={workshop}
          selectedState={selectedWorkshop}
          setSelectedState={setSelectedWorkshop}
          activated={(selected || purchasedPhysical)}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedWorkshop(null)
            }
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 6% SST
          </strong>
        )}
        <button
          type="submit"
          className="btn btn-lg btn-primary d-block mx-auto mt-4"
          disabled={selected === null && selectedWorkshop === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  )
}

export default ProductSelection
